import { message, notification } from "antd";

export function handleSuccess(data: any) {
  if (data?.title || data?.message) {
    return notification.success({
      message: data?.title || "",
      description: data?.message || "",
    });
  }

  notification.success({
    message: "Success",
    description: "",
  });
}

export function handleSuccessMessage(data: any) {
  if (data?.message) {
    return message.success(data.message);
  }

  message.success("Success");
}
