import * as React from "react";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Skeleton,
  Col,
  Row,
  Checkbox,
} from "antd";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  useExternalID,
  useExternalUpdate,
  useFeedStockAvailable,
} from "../../../services/admin/externalDaily/useExternalDaily";
import ImageUpload from "../../common/form/ImageUpload/ImageUpload";
import { getImageNameFromURI } from "../../../utils/string";
//   import BoilerSoldModal from "./modal/BoilerSoldModal";
import { normFile } from "../../../utils";

import { useWeatherApiRequest } from "../../../services/admin/lot/useWeatherServices";

import "./external.scss";
import "../../auth/login/Login.scss";
import AvgBodyWeightsModal from "./modal/AvgBodyWeights";

export default function ExternalInputs() {
  const [form] = Form.useForm();
  const Params = useParams();
  const { TextArea } = Input;
  const location = useLocation();
  const checklist = location?.state?.data;
  const langChecked = location?.state?.langChecked;

  const { isLoading, data, isSuccess } = useExternalID(Params.id);
  const { data: availableStock } = useFeedStockAvailable(
    data?.data?.farmer?._id,
    isSuccess
  );
  const { data: weather } = useWeatherApiRequest(
    data?.data?.address?.location?.coordinates[1],
    data?.data?.address?.location?.coordinates[0],
    isSuccess
  );
  const externalUpdate = useExternalUpdate();

  //feeds filters
  const [selectedItems, setSelectedItems] = React.useState<any>([]);
  const filteredOptions = availableStock?.data?.filter(
    (feedObj: any) => !selectedItems.includes(feedObj?.feedType)
  );
  const [feedIndexCount, setFeedIndexCount] = React.useState<any>(0);

  //avgbodyweight
  const [showAvgBodyWeight, setShowAvgBodyWeight] = React.useState(false);
  const [avgBodyWeights, setAvgBodyWeights] = React.useState<any>([]);

  //temperature

  const [timeIntervalSetting, setTimeIntervalSetting] = React.useState<any>({
    //temperature
    disableChangeableValueOfMorning: false,
    disableChangeableValueOfDay: false,
    disableChangeableValueOfEvening: false,
    disableChangeableValueOfNight: false,

    disabledMorning: false,
    disabledDay: false,
    disabledEvening: false,
    disabledNight: false,

    showMorningTemperature: false,
    showDayTemperature: false,
    showEveningTemperature: false,
    showNightTemperature: false,

    //svt
    disableChangeableValueOfMorningSVT: false,
    disableChangeableValueOfDaySVT: false,
    disableChangeableValueOfNightSVT: false,

    disabledMorningSVT: false,
    disabledDaySVT: false,
    disabledNightSVT: false,

    showMorningSVT: false,
    showDaySVT: false,
    showNightSVT: false,
  });

  const handleAvgBodyWeightModalClose = (avgBodyWeights: any) => {
    form.setFieldValue(
      "avgBodyWeight",
      +(
        avgBodyWeights.reduce((a: number, c: any) => a + c.weight, 0) /
        avgBodyWeights?.length
      ).toFixed(2)
    );
    setAvgBodyWeights(avgBodyWeights);
    form.setFieldValue("avgBodyWeights", avgBodyWeights);
  };

  const handleTimeIntervalsOfTemperature = () => {
    let hourOfDay = new Date().getHours();

    //temperature
    switch (true) {
      case hourOfDay < 8:
        setTimeIntervalSetting({
          ...timeIntervalSetting,
          //temperature
          disabledDay: true,
          disabledEvening: true,
          disabledNight: true,
          showMorningTemperature: true,
          disableChangeableValueOfMorning: true,

          //svt
          disabledDaySVT: true,
          disabledNightSVT: true,
          showMorningSVT: true,
          disableChangeableValueOfMorningSVT: true,
        });
        break;

      case hourOfDay < 12:
        setTimeIntervalSetting({
          ...timeIntervalSetting,
          //temperature
          disabledEvening: true,
          disabledNight: true,
          showDayTemperature: true,
          disableChangeableValueOfDay: true,

          //svt
          disabledNightSVT: true,
          showDaySVT: true,
          disableChangeableValueOfDaySVT: true,
        });
        break;

      case hourOfDay < 18:
        setTimeIntervalSetting({
          ...timeIntervalSetting,
          //temperature
          disabledNight: true,
          showEveningTemperature: true,
          disableChangeableValueOfEvening: true,

          //svt
          disabledNightSVT: true,
          showDaySVT: true,
          disableChangeableValueOfDaySVT: true,
        });
        break;

      default:
        setTimeIntervalSetting({
          ...timeIntervalSetting,
          //temperature
          showNightTemperature: true,
          disableChangeableValueOfNight: true,

          //svt
          showNightSVT: true,
          disableChangeableValueOfNightSVT: true,
        });
    }

    //SVT enable if different time frame
    // switch (true) {
    //   case hourOfDay < 8:
    //     setTimeIntervalSetting({
    //       ...timeIntervalSetting,
    //       disabledDaySVT: true,
    //       disabledNightSVT: true,
    //       showMorningSVT: true,
    //       disableChangeableValueOfMorningSVT: true,
    //     });
    //     break;

    //   case hourOfDay < 18:
    //     setTimeIntervalSetting({
    //       ...timeIntervalSetting,
    //       disabledNightSVT: true,
    //       showDaySVT: true,
    //       disableChangeableValueOfDaySVT: true,
    //     });
    //     break;

    //   default:
    //     setTimeIntervalSetting({
    //       ...timeIntervalSetting,
    //       showNightSVT: true,
    //       disableChangeableValueOfNightSVT: true,
    //     });
    // }

    // return initialFormValues;
  };
  React.useEffect(() => {
    let initialFormValues = {
      checklist: data?.data?.checklist || checklist,
      address: data?.data?.address?.name,
      openingStock: data?.data?.openingStock,
      feeds: [],
      avgBodyWeights: data?.data?.avgBodyWeights,
      avgBodyWeight: null,
      house: data?.data?.house?.map((house: any) => ({
        pen: house?.pen?.map((pen: any) => ({
          noOfChicks: pen?.noOfChicks,
          mortalityNo: pen?.mortalityNo,
        })),
      })),
      noOfIsolatedChicks: data?.data?.noOfIsolatedChicks,
      noOfIsolatedChicksRecovered: data?.data?.noOfIsolatedChicksRecovered,
      remarks: data?.data?.remarks,
      waterConsumption: data?.data?.waterConsumption,

      morning: {
        supplements: data?.data?.morning?.supplements,
        treatments: data?.data?.morning?.treatments,
        vaccine: data?.data?.morning?.vaccine,
        bills:
          data?.data?.morning?.bills > 0
            ? data?.data?.morning?.bills?.map(
                (item: string, index: number) => ({
                  uid: index,
                  name: getImageNameFromURI(item),
                  status: "done",
                  url: item,
                })
              )
            : [],
      },
      day: {
        supplements: data?.data?.day?.supplements,
        treatments: data?.data?.day?.treatments,
        vaccine: data?.data?.day?.vaccine,
        bills:
          data?.data?.day?.bills > 0
            ? data?.data?.day?.bills?.map((item: string, index: number) => ({
                uid: index,
                name: getImageNameFromURI(item),
                status: "done",
                url: item,
              }))
            : [],
      },
      night: {
        supplements: data?.data?.night?.supplements,
        treatments: data?.data?.night?.treatments,
        vaccine: data?.data?.night?.vaccine,
        bills:
          data?.data?.night?.bills > 0
            ? data?.data?.night?.bills?.map((item: string, index: number) => ({
                uid: index,
                name: getImageNameFromURI(item),
                status: "done",
                url: item,
              }))
            : [],
      },
      temperature: {
        morning: data?.data?.temperature?.morning,
        day: data?.data?.temperature?.day,
        evening: data?.data?.temperature?.evening,
        night: data?.data?.temperature?.night,
      },
      outsideTemperature: {
        morning: data?.data?.outsideTemperature?.morning
          ? data?.data?.outsideTemperature?.morning
          : undefined,
        day: data?.data?.outsideTemperature?.day
          ? data?.data?.outsideTemperature?.day
          : undefined,
        evening: data?.data?.outsideTemperature?.evening
          ? data?.data?.outsideTemperature?.evening
          : undefined,
        night: data?.data?.outsideTemperature?.night
          ? data?.data?.outsideTemperature?.night
          : undefined,
      },
      // stvBills:
      //   data?.data?.stvBills?.length > 0
      //     ? data?.data?.stvBills?.map((item: string, index: number) => ({
      //         uid: index,
      //         name: getImageNameFromURI(item),
      //         status: "done",
      //         url: item,
      //       }))
      //     : [],
    };

    form.setFieldsValue(initialFormValues);
    handleTimeIntervalsOfTemperature();
    setAvgBodyWeights(initialFormValues?.avgBodyWeights);
    // form.setFieldsValue(handleTimeIntervalsOfTemperature(initialFormValues));
  }, [form, data]);

  const handleSubmit = async (values: any) => {
    delete values["address"];
    let outsideTemperatureObj: any = {
      morning: undefined,
      day: undefined,
      evening: undefined,
      night: undefined,
    };
    if (timeIntervalSetting?.disableChangeableValueOfMorning) {
      outsideTemperatureObj["morning"] = weather?.data?.main?.temp;
    }
    if (timeIntervalSetting?.disableChangeableValueOfDay) {
      outsideTemperatureObj["day"] = weather?.data?.main?.temp;
    }
    if (timeIntervalSetting?.disableChangeableValueOfEvening) {
      outsideTemperatureObj["evening"] = weather?.data?.main?.temp;
    }
    if (timeIntervalSetting?.disableChangeableValueOfNight) {
      outsideTemperatureObj["night"] = weather?.data?.main?.temp;
    }

    const payload = {
      ...Object.assign(
        data?.data,
        { outsideTemperature: outsideTemperatureObj },
        values
      ),
      feeds: values.feeds.filter((x: any) => {
        return x?.consume && x?.feedType;
      }),
      // feeds: values.feeds.filter((o: any) =>
      //   Object.values(o).some((v) => v !== null)
      // ),
      // feeds: values.feeds
      //   .filter((o: any) => Object.values(o).some((v) => v !== null))
      //   .filter((element: any) => {
      //     if (Object.keys(element).length !== 0) {
      //       return true;
      //     }

      //     return false;
      //   }),
      morning: {
        supplements: values?.morning?.supplements,
        treatments: values?.morning?.treatments,
        vaccine: values?.morning?.vaccine,
        bills:
          values?.morning?.bills?.map(
            (item: any) => item?.response?.data?.path || item?.url
          ) || [],
      },
      day: {
        supplements: values?.day?.supplements,
        treatments: values?.day?.treatments,
        vaccine: values?.day?.vaccine,
        bills:
          values?.day?.bills?.map(
            (item: any) => item?.response?.data?.path || item?.url
          ) || [],
      },
      night: {
        supplements: values?.night?.supplements,
        treatments: values?.night?.treatments,
        vaccine: values?.night?.vaccine,
        bills:
          values?.night?.bills?.map(
            (item: any) => item?.response?.data?.path || item?.url
          ) || [],
      },
      // stvBills:
      //   values?.stvBills?.map(
      //     (item: any) => item?.response?.data?.path || item?.url
      //   ) || [],
    };

    try {
      // debugger;

      await externalUpdate.mutateAsync({
        id: Params.id,
        payload,
      });
      form.resetFields();
    } catch (error) {
      return;
    }
  };

  return (
    <div className="LoginPage relative bg-primarySkLight">
      <div className="container py-6">
        <div className="bg-white  pb-3 px-4 rounded-md w-full md:w-2/3 lg:w-1/2 mx-auto my-6">
          <h1 className="text-lg capitalize text-primarySk font-semibold">
            {langChecked ? "Daily Entries" : "दैनिक निवेश"}
          </h1>
          <div className="mt-4">
            {isLoading && <Skeleton />}
            {data && (
              <Form
                form={form}
                layout="vertical"
                // autoComplete="off"
                onFinish={handleSubmit}
                // initialValues={{
                //   feeds: [""],
                // }}
              >
                <div className="bg-primarySkLight rounded-md">
                  <div className="px-4 pb-0.5 mb-2">
                    <Form.Item
                      label={langChecked ? "Address" : "ठेगाना"}
                      name="address"
                    >
                      <Input
                        size="large"
                        // placeholder='Enter your address'
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item>
                      {data?.data?.house
                        ? data?.data?.house.map((house: any, index: number) => {
                            return (
                              <Row gutter={[15, 15]} key={index}>
                                <Col span={24}>
                                  <h2 className="text-base font-semibold uppercase bg-primarySk text-white py-2 px-4 rounded-sm">
                                    {langChecked ? "House" : "घर"}{" "}
                                    {house?.houseNumber}
                                  </h2>
                                </Col>
                                <Col span={24}>
                                  {house?.pen?.map((item: any, i: number) => {
                                    return (
                                      <Row
                                        className="px-4"
                                        gutter={[15, 15]}
                                        key={index + "" + i}
                                      >
                                        <Col span={24} md={12}>
                                          <Form.Item
                                            label={
                                              langChecked
                                                ? "Number of Chicks"
                                                : "चल्लाहरूको संख्या"
                                            }
                                            rules={[
                                              {
                                                required: true,
                                              },
                                            ]}
                                            name={[
                                              "house",
                                              index,
                                              "pen",
                                              i,
                                              "noOfChicks",
                                            ]}
                                          >
                                            <InputNumber
                                              min={0}
                                              disabled
                                              size="large"
                                              className="w-full"

                                              // placeholder='Enter number of chicks'
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                          <Form.Item
                                            name={[
                                              "house",
                                              index,
                                              "pen",
                                              i,
                                              "mortalityNo",
                                            ]}
                                            label={
                                              langChecked
                                                ? "Mortality No."
                                                : "मृत्यु / मृत्युदर"
                                            }
                                            rules={[
                                              {
                                                required: true,
                                                message: langChecked
                                                  ? "Enter Mortality No"
                                                  : "कृपया  मृत्यु / मृत्युदर प्रविष्ट गर्नुहोस्",
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              min={0}
                                              max={item.noOfChicks}
                                              size="large"
                                              className="w-full"
                                              // placeholder='Enter mortality number'
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </Col>
                              </Row>
                            );
                          })
                        : []}
                    </Form.Item>
                  </div>

                  <div className="px-4 pb-0.5 mb-4">
                    <Row gutter={[15, 15]}>
                      <Col span={24}>
                        <Form.Item
                          label={langChecked ? "Remarks" : "टिप्पणीहरू"}
                          name="remarks"
                        >
                          <TextArea rows={4} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={
                            langChecked
                              ? "No. of Isolated chicks"
                              : "पृथक चल्लाहरूको संख्या"
                          }
                          name="noOfIsolatedChicks"
                        >
                          <InputNumber
                            min={0}
                            size="large"
                            // placeholder='Enter isolated chicks'
                            className="w-full"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={
                            langChecked
                              ? "No. of Isolated Chicks Recovered"
                              : "निको भएको चल्लाको संख्या"
                          }
                          name="noOfIsolatedChicksRecovered"
                        >
                          <InputNumber
                            min={0}
                            size="large"
                            // placeholder='Enter isolated chicks'
                            className="w-full"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div className="bg-primarySkLight p-4 mb-4 rounded-md">
                    <Form.List name="feeds">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map((field, index) => {
                              // setFeedIndexCount(index + 1);
                              return (
                                <div key={index}>
                                  <div className="flex items-center justify-between gap-x-4 pb-1 mb-4 border-b border-primarySk">
                                    <h2 className="text-sm font-semibold uppercase text-primarySk pb-2">
                                      {langChecked
                                        ? "Feed Consumption"
                                        : "दाना खपत "}
                                      {index + 1}
                                    </h2>
                                    {fields.length > 1 ? (
                                      <div>
                                        <MinusCircleOutlined
                                          size={44}
                                          className=""
                                          onClick={() => {
                                            remove(field.name);
                                            const newSelectedItems = [
                                              ...selectedItems,
                                            ];
                                            newSelectedItems.splice(index, 1);
                                            setSelectedItems(newSelectedItems);
                                            setFeedIndexCount(
                                              feedIndexCount - 1
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                  <div>
                                    <Row gutter={[15, 15]} key={index}>
                                      <Col span={12}>
                                        <Form.Item
                                          name={[field.name, "feedType"]}
                                          label={
                                            langChecked
                                              ? "Feed Type "
                                              : "दाना प्रकार "
                                          }
                                        >
                                          <Select
                                            size="large"
                                            className="w-full"
                                            allowClear
                                            options={filteredOptions?.map(
                                              (item: any) => ({
                                                value: item.feedType,
                                                label: item.feedType,
                                              })
                                            )}
                                            value={selectedItems[index]}
                                            onChange={(value) => {
                                              const newSelectedItems = [
                                                ...selectedItems,
                                              ];
                                              newSelectedItems[index] = value;
                                              setSelectedItems(
                                                newSelectedItems
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={12}>
                                        <Form.Item
                                          label={
                                            langChecked ? "Consume" : "दाना खपत"
                                          }
                                          name={[field.name, "consume"]}
                                        >
                                          <InputNumber
                                            min={1}
                                            size="large"
                                            // placeholder='Enter feed stocks'
                                            className="w-full"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              );
                            })}

                            <Button
                              block
                              type="primary"
                              size="large"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                add();
                                setFeedIndexCount(feedIndexCount + 1);
                              }}
                              disabled={
                                feedIndexCount === availableStock?.data?.length
                              }
                            >
                              {langChecked ? "Feed Consumption" : "दाना खपत"}
                            </Button>
                          </>
                        );
                      }}
                    </Form.List>
                  </div>

                  <div className="px-4 pb-0.5 mb-4">
                    <Row gutter={[15, 15]}>
                      <Col span={24} md={12}>
                        <Form.Item
                          name="waterConsumption"
                          label={
                            langChecked ? "Water Intake (Ltr)" : "पानी को खपत"
                          }
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: langChecked
                          //       ? "Enter water consumption"
                          //       : "कृपया पानी सेवन प्रविष्ट गर्नुहोस्",
                          //   },
                          //   () => ({
                          //     validator(_, value) {
                          //       if (value !== 0) {
                          //         return Promise.resolve();
                          //       }
                          //       return Promise.reject(
                          //         new Error(
                          //           langChecked
                          //             ? "Value cannot be 0"
                          //             : "खपत 0 हुन सक्दैन"
                          //         )
                          //       );
                          //     },
                          //   }),
                          // ]}
                        >
                          <InputNumber
                            size="large"
                            className="w-full"
                            // placeholder='Enter your feed consumption'
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          label={
                            langChecked
                              ? "avg. Body Weight"
                              : "शारीरिक वजन (तौल)"
                          }
                          name="avgBodyWeight"
                          rules={[
                            {
                              required: data?.data?.allowAvgBodyWeight,
                              message: langChecked
                                ? "avg. Body Weight"
                                : "शारीरिक वजन (तौल)",
                            },
                            () => ({
                              validator(_, value) {
                                if (value !== 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    langChecked
                                      ? "Value cannot be 0"
                                      : "मूल्य 0 हुन सक्दैन"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="large"
                            className="w-full"
                            // placeholder='Enter no. of boiler sold'
                            onClick={() => {
                              setShowAvgBodyWeight(true);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className=" pb-0.5 mb-4 ">
                    <Row gutter={[15, 15]}>
                      <Col span={24}>
                        <h2 className="text-base font-semibold uppercase bg-primarySk text-white py-2 px-4 rounded-sm">
                          {langChecked ? "Temperature" : "तापक्रम"}
                        </h2>
                      </Col>
                      <Row gutter={[2, 2]} className="w-full px-4 ">
                        <Col span={24} md={6}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              checked={
                                timeIntervalSetting?.showMorningTemperature
                              }
                              disabled={timeIntervalSetting?.disabledMorning}
                              onChange={() => {
                                if (
                                  !timeIntervalSetting.disableChangeableValueOfMorning
                                ) {
                                  setTimeIntervalSetting({
                                    ...timeIntervalSetting,
                                    showMorningTemperature:
                                      !timeIntervalSetting?.showMorningTemperature,
                                  });
                                }
                              }}
                            >
                              {langChecked ? "Morning" : "बिहान"}
                            </Checkbox>
                          </Form.Item>
                          {timeIntervalSetting?.showMorningTemperature && (
                            <Form.Item
                              className="mb-2 mr-2"
                              name={["temperature", "morning"]}
                            >
                              <Input
                                size="large"
                                className="w-full"
                                // placeholder='Enter no. of boiler sold'
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={24} md={6}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              checked={timeIntervalSetting?.showDayTemperature}
                              disabled={timeIntervalSetting?.disabledDay}
                              onChange={() => {
                                if (
                                  !timeIntervalSetting?.disableChangeableValueOfDay
                                ) {
                                  setTimeIntervalSetting({
                                    ...timeIntervalSetting,
                                    showDayTemperature:
                                      !timeIntervalSetting?.showDayTemperature,
                                  });
                                }
                              }}
                            >
                              {langChecked ? "Day" : "दिन"}
                            </Checkbox>
                          </Form.Item>
                          {timeIntervalSetting?.showDayTemperature && (
                            <Form.Item
                              className="mb-2 mr-2"
                              name={["temperature", "day"]}
                            >
                              <Input
                                size="large"
                                className="w-full"
                                // placeholder='Enter no. of boiler sold'
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={24} md={6}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              checked={
                                timeIntervalSetting?.showEveningTemperature
                              }
                              disabled={timeIntervalSetting?.disabledEvening}
                              onChange={() => {
                                if (
                                  !timeIntervalSetting?.disableChangeableValueOfEvening
                                ) {
                                  setTimeIntervalSetting({
                                    ...timeIntervalSetting,

                                    showEveningTemperature:
                                      !timeIntervalSetting?.showEveningTemperature,
                                  });
                                }
                              }}
                            >
                              {langChecked ? "Evening" : "साँझ"}
                            </Checkbox>
                          </Form.Item>
                          {timeIntervalSetting?.showEveningTemperature && (
                            <Form.Item
                              className="mb-2 mr-2"
                              name={["temperature", "evening"]}
                            >
                              <Input
                                size="large"
                                className="w-full"
                                // placeholder='Enter no. of boiler sold'
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={24} md={6}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              checked={
                                timeIntervalSetting?.showNightTemperature
                              }
                              disabled={timeIntervalSetting?.disabledNight}
                              onChange={() => {
                                if (
                                  !timeIntervalSetting?.disableChangeableValueOfNight
                                ) {
                                  setTimeIntervalSetting({
                                    ...timeIntervalSetting,

                                    showNightTemperature:
                                      !timeIntervalSetting?.showNightTemperature,
                                  });
                                }
                              }}
                            >
                              {langChecked ? "Night" : "रात"}
                            </Checkbox>
                          </Form.Item>
                          {timeIntervalSetting?.showNightTemperature && (
                            <Form.Item
                              className="mb-2 mr-2"
                              name={["temperature", "night"]}
                            >
                              <Input
                                size="large"
                                className="w-full"
                                // placeholder='Enter no. of boiler sold'
                              />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </div>
                  <div className="  pb-0.5 mb-4 ">
                    <Row gutter={[15, 15]}>
                      <Col span={24}>
                        <h2 className="text-base font-semibold uppercase bg-primarySk text-white py-2 px-4 rounded-sm">
                          {langChecked
                            ? "Vaccine/Supplements/Treatments"
                            : "भ्याक्सिनेसन/पुरक/उपचार"}
                        </h2>
                      </Col>
                      <Row gutter={[2, 2]} className="w-full px-4">
                        <Col span={24} md={8}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              checked={timeIntervalSetting?.showMorningSVT}
                              disabled={timeIntervalSetting?.disabledMorningSVT}
                              onChange={() => {
                                if (
                                  !timeIntervalSetting.disableChangeableValueOfMorningSVT
                                ) {
                                  setTimeIntervalSetting({
                                    ...timeIntervalSetting,
                                    showMorningSVT:
                                      !timeIntervalSetting?.showMorningSVT,
                                  });
                                }
                              }}
                            >
                              {langChecked ? "Morning" : "बिहान"}
                            </Checkbox>
                          </Form.Item>
                          {timeIntervalSetting?.showMorningSVT && (
                            <>
                              <Form.Item
                                label={langChecked ? "Supplements" : "पुरक"}
                                className="mb-2 md:mr-2"
                                name={["morning", "suppliments"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>
                              <Form.Item
                                label={langChecked ? "Vaccine" : "भ्याक्सिनेसन"}
                                className="mb-2  md:mr-2"
                                name={["morning", "vaccine"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>{" "}
                              <Form.Item
                                label={langChecked ? "Treatments" : "उपचार"}
                                className="mb-2 md:mr-2"
                                name={["morning", "treatments"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>
                              <Col span={24}>
                                <Form.Item
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues?.salesBills !==
                                    curValues?.salesBills
                                  }
                                  noStyle
                                >
                                  {({ getFieldValue }) => (
                                    <Form.Item
                                      className="m-0"
                                      name={["morning", "bills"]}
                                      label={langChecked ? "Bills" : "बिलहरू"}
                                      valuePropName="fileList"
                                      getValueFromEvent={normFile}
                                    >
                                      <ImageUpload
                                        listType="picture-card"
                                        maxCount={4}
                                        folder="svt-morning-bills"
                                        hideUploader={
                                          data?.data?.stvBills?.length > 4
                                          // getFieldValue("stvBills")?.length > 4
                                        }
                                      />
                                    </Form.Item>
                                  )}
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Col>
                        <Col span={24} md={8}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              checked={timeIntervalSetting?.showDaySVT}
                              disabled={timeIntervalSetting?.disabledDaySVT}
                              onChange={() => {
                                if (
                                  !timeIntervalSetting.disableChangeableValueOfDaySVT
                                ) {
                                  setTimeIntervalSetting({
                                    ...timeIntervalSetting,
                                    showDaySVT:
                                      !timeIntervalSetting?.showDaySVT,
                                  });
                                }
                              }}
                            >
                              {langChecked ? "Day" : "दिन"}
                            </Checkbox>
                          </Form.Item>
                          {timeIntervalSetting?.showDaySVT && (
                            <>
                              <Form.Item
                                label={langChecked ? "Supplements" : "पुरक"}
                                className="mb-2 md:mr-2"
                                name={["day", "suppliments"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>
                              <Form.Item
                                label={langChecked ? "Vaccine" : "भ्याक्सिनेसन"}
                                className="mb-2  md:mr-2"
                                name={["day", "vaccine"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>{" "}
                              <Form.Item
                                label={langChecked ? "Treatments" : "उपचार"}
                                className="mb-2 md:mr-2"
                                name={["day", "treatments"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>
                              <Col span={24}>
                                <Form.Item
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues?.salesBills !==
                                    curValues?.salesBills
                                  }
                                  noStyle
                                >
                                  {({ getFieldValue }) => (
                                    <Form.Item
                                      className="m-0"
                                      name={["day", "bills"]}
                                      label={langChecked ? "Bills" : "बिलहरू"}
                                      valuePropName="fileList"
                                      getValueFromEvent={normFile}
                                    >
                                      <ImageUpload
                                        listType="picture-card"
                                        maxCount={4}
                                        folder="svt-day-bills"
                                        hideUploader={
                                          data?.data?.stvBills?.length > 4
                                          // getFieldValue("stvBills")?.length > 4
                                        }
                                      />
                                    </Form.Item>
                                  )}
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Col>

                        <Col span={24} md={8}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              checked={timeIntervalSetting?.showNightSVT}
                              disabled={timeIntervalSetting?.disabledNightSVT}
                              onChange={() => {
                                if (
                                  !timeIntervalSetting.disableChangeableValueOfNightSVT
                                ) {
                                  setTimeIntervalSetting({
                                    ...timeIntervalSetting,
                                    showNightSVT:
                                      !timeIntervalSetting?.showNightSVT,
                                  });
                                }
                              }}
                            >
                              {langChecked ? "Night" : "रात"}
                            </Checkbox>
                          </Form.Item>

                          {timeIntervalSetting?.showNightSVT && (
                            <>
                              <Form.Item
                                label={langChecked ? "Supplements" : "पुरक"}
                                className="mb-2 md:mr-2"
                                name={["night", "suppliments"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>
                              <Form.Item
                                label={langChecked ? "Vaccine" : "भ्याक्सिनेसन"}
                                className="mb-2  md:mr-2"
                                name={["night", "vaccine"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>{" "}
                              <Form.Item
                                label={langChecked ? "Treatments" : "उपचार"}
                                className="mb-2 md:mr-2"
                                name={["night", "treatments"]}
                              >
                                <Input
                                  size="large"
                                  className="w-full"
                                  // placeholder='Enter no. of boiler sold'
                                />
                              </Form.Item>
                              <Col span={24}>
                                <Form.Item
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues?.salesBills !==
                                    curValues?.salesBills
                                  }
                                  noStyle
                                >
                                  {({ getFieldValue }) => (
                                    <Form.Item
                                      className="m-0"
                                      name={["night", "bills"]}
                                      label={langChecked ? "Bills" : "बिलहरू"}
                                      valuePropName="fileList"
                                      getValueFromEvent={normFile}
                                    >
                                      <ImageUpload
                                        listType="picture-card"
                                        maxCount={4}
                                        folder="svt-night-bills"
                                        hideUploader={
                                          data?.data?.stvBills?.length > 4
                                          // getFieldValue("stvBills")?.length > 4
                                        }
                                        langChecked={langChecked}
                                      />
                                    </Form.Item>
                                  )}
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="rounded-sm bg-primaryGreen capitalize text-white hover:text-white hover:underline"
                  style={{
                    fontSize: 15,
                  }}
                >
                  {langChecked ? "Save" : "सेभ गर्नुहोस्"}
                </Button>
              </Form>
            )}
          </div>
        </div>
      </div>
      {showAvgBodyWeight && (
        <AvgBodyWeightsModal
          data={{
            avgBodyWeights: avgBodyWeights,
            openingStock: data?.data?.openingStock,
          }}
          open={showAvgBodyWeight}
          cancel={() => setShowAvgBodyWeight(false)}
          onClose={handleAvgBodyWeightModalClose}
          langChecked={langChecked}
        />
      )}
    </div>
  );
}
