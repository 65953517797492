import * as React from "react";
export const Root = React.lazy(() => import("../components/root/Root"));
export const NotFound = React.lazy(
  () => import("../components/pages/notFound/NotFound")
);
export const ErrorFallback = React.lazy(
  () => import("../components/errorFallback/ErrorFallBack")
);
//
export const Layout = React.lazy(() => import("../components/layout/Layout"));
export const Home = React.lazy(() => import("../components/home/Home"));
export const Login = React.lazy(() => import("../components/auth/login/Login"));
export const ForgetPassword = React.lazy(
  () => import("../components/auth/forget-password/ForgetPassword")
);
export const ResetOtpVerify = React.lazy(
  () => import("../components/auth/otp/Otp")
);
export const ResetPassword = React.lazy(
  () => import("../components/auth/reset-password/ResetPassword")
);

export const Externalnputs = React.lazy(
  () => import("../components/pages/externalDailyInputs/ExteralInputs")
);

export const ExternalDailyInputs = React.lazy(
  () => import("../components/pages/externalDailyInputs/ExternalDailyInputs")
);

export const ExternalInformation = React.lazy(
  () =>
    import(
      "../components/pages/externalDailyInputs/informtaion/ExternalInformation"
    )
);
export const ExternalSuggestion = React.lazy(
  () =>
    import(
      "../components/pages/externalDailyInputs/informtaion/Suggestion/ExternalSuggestion"
    )
);
export const Page = React.lazy(() => import("../components/misc/page/Page"));

// admin
export const RequireAdmin = React.lazy(
  () => import("./requireAdmin/RequireAdmin")
);
export const AdminDashboard = React.lazy(
  () => import("../components/admin/dashboard/Dashboard")
);
export const AdminProfile = React.lazy(
  () => import("../components/admin/profile/Profile")
);
export const AdminChangePassword = React.lazy(
  () => import("../components/admin/changePassword/ChangePassword")
);
export const AdminList = React.lazy(
  () => import("../components/admin/users/admin/adminList")
);
export const AdminFarmerList = React.lazy(
  () => import("../components/admin/users/farmer/FarmerList")
);
export const AdminFarmerDetails = React.lazy(
  () => import("../components/admin/users/farmer/farmerDetails/FarmerDetails")
);
export const AdminFarmerStaffDetails = React.lazy(
  () =>
    import(
      "../components/admin/users/farmer/farmerStaffDetails/FarmerStaffDetails"
    )
);
export const AdminVerify = React.lazy(
  () => import("../components/admin/lot/verify/Verify")
);
export const AdminVerfiyDetails = React.lazy(
  () => import("../components/admin/lot/verify/details/VerifyDetails")
);
export const AdminVerifyDailyInputs = React.lazy(
  () => import("../components/admin/lot/verify/dailyInputs/VerifyDailyInputs")
);
export const AdminUnVerify = React.lazy(
  () => import("../components/admin/lot/unverify/UnVerify")
);
export const AdminUnverfiyDetails = React.lazy(
  () => import("../components/admin/lot/unverify/details/UnverifyDetails")
);
export const AdminContactMessages = React.lazy(
  () => import("../components/admin/contact/contactMessages/ContactMessages")
);
export const AdminOrderEnquiry = React.lazy(
  () => import("../components/admin/contact/orderEnquiry/OrderEnquiry")
);
export const AdminPages = React.lazy(
  () => import("../components/admin/misc/pages/Pages")
);
export const AdminPage = React.lazy(
  () => import("../components/admin/misc/pages/form/PageForm")
);
export const AdminNotice = React.lazy(
  () => import("../components/admin/notice/noticeCommon/CommonNotice")
);
export const AdminFarmerNotice = React.lazy(
  () => import("../components/admin/notice/farmer/FarmerNotice")
);
export const AdminScheduleNotice = React.lazy(
  () => import("../components/admin/notice/schedule/ScheduleNotice")
);
export const AdminRegisterNotice = React.lazy(
  () => import("../components/admin/notice/registerNotice/RegisterNotice")
);
export const AdminOrderNotice = React.lazy(
  () => import("../components/admin/notice/orderNotice/OrderNotice")
);
export const AdminMarket = React.lazy(
  () => import("../components/admin/market/AdminMarket")
);
export const AdminResources = React.lazy(
  () => import("../components/admin/resources/Resources")
);
export const AdminVetRoom = React.lazy(
  () => import("../components/admin/vet/Vet")
);
export const AdminVetRoomDetails = React.lazy(
  () => import("../components/admin/vet/details/VetDetails")
);
export const AdminHatchery = React.lazy(
  () => import("../components/admin/misc/hatchery/Hatchery")
);

export const AdminBrands = React.lazy(
  () => import("../components/admin/misc/adminBrands/AdminBrands")
);

export const AdminCatalouge = React.lazy(
  () => import("../components/admin/misc/catalouge/Catalogue")
);

export const AdminCatalogueForm = React.lazy(
  () => import("../components/admin/misc/catalouge/form/Form")
);

export const AdminSiteSettings = React.lazy(
  () => import("../components/admin/misc/siteSettings/SiteSetting")
);
