import * as React from "react";
import { handleAvgBodyWeight } from "../../../../utils/index";
import { Button, Form, InputNumber, Modal } from "antd";
type BoilerModalProps = {
  open: boolean;
  cancel: () => void;
  data: any;
  onClose: any;
  langChecked: boolean;
};

export default function AvgBodyWeightsModal({
  open,
  cancel,
  data,
  onClose,
  langChecked,
}: BoilerModalProps) {
  const [avgForm] = Form.useForm();

  const [avgBodyWeights, setAvgBodyWeights] = React.useState<any>([]);

  React.useEffect(() => {
    const initialFormValues = {
      openingStock: data?.openingStock,
      avgBodyWeights: data?.avgBodyWeights || [],
      avgBodyWeight: null,
    };
    setAvgBodyWeights(
      handleAvgBodyWeight(data?.openingStock, data?.avgBodyWeights || [])
    );
    avgForm.setFieldsValue(initialFormValues);
  }, [avgForm, data]);

  const handleSubmit = (values: any) => {
    console.log("values", values);
    // const salesJsonValues = values?.sales;
    // delete values["house"];
    // const boilerSold = values.sales?.map((value: any) => {
    //   return value?.pen
    //     ?.map((item: any) => {
    //       return item?.noOfBoilerSold;
    //     })
    //     .reduce(
    //       (accumulator: any, currentValue: any) => accumulator + currentValue,
    //       0
    //     );
    // });
    // const totalBoilerSold = boilerSold.reduce(
    //   (prev: any, current: any) => prev + current
    // );
    // onClose(totalBoilerSold, salesJsonValues);
    onClose(values?.avgBodyWeights)
    cancel();
  };

  return (
    <Modal
      open={open}
      footer={false}
      onCancel={cancel}
      title={
        <h1 className="text-primarySk capitalize text-lg">
          {langChecked ? "Avg. Body Weight" : "कुखुरा को तौल प्रविष्ट गर्नुहोस्"}
        </h1>
      }
    >
      <Form form={avgForm} layout="horizontal" onFinish={handleSubmit}>
        {avgBodyWeights?.map((obj: any, index: number) => {
          return (
            <Form.Item
              key={index}
              name={["avgBodyWeights", index, "weight"]}
              label={
                <h1 className="text-[.9375rem]">
                  {langChecked ? "Weight" : "तौल"} {index+1}
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: langChecked
                    ? "Please enter weight of Chicken"
                    : "कृपया कुखुरा को तौल प्रविष्ट गर्नुहोस्",
                },
              ]}
            >
              <InputNumber min={1} className="w-full" size="large" />
            </Form.Item>
          );
        })}

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="w-full mt-4"
        >
          {langChecked ? "Submit" : "पेश गर्नुहोस्"}
        </Button>
      </Form>
    </Modal>
  );
}
