const queryKeys = {
  auth: {
    user: "authUser",
    notifications: "authNotification",
  },
  admin: {
    resource: "adminResource",
    resources: "adminResources",
    vet: "adminVet",
    vets: "adminVets",
    market: {
      marketManagement: "adminMarketManagement",
    },
    contactManagement: {
      messages: "adminContactMessages",
      orderEnquiry: "adminOrderEnquiry",
    },
    lot: {
      verify: "adminVerified",
      verifyDetails: "adminVerifiedDetails",
      unVerify: "adminUnVerified",
      unVerifyDetail: "adminUnVerifiedDetails",
      hatcheryForSelect: "hatcheryForSelect",
      dailyInputs: "adminDailyInputs",
      avgWgh: "adminAvgWght",
      dayMrt: "adminDayMrt",
      dayPerform: "adminDayPerform",
      incomePerformance: "adminIncomePerformance",
      verifyStatus: "adminVerifyStatus",
      dashboard: "dashboard",
    },
    notice: {
      common: "adminCommonNotice",
      schedule: "adminScheduleNotice",
      register: "adminRegisterNotice",
      order: "adminOrderNotice",
      farmers: "adminFarmerNotices",
    },
    misc: {
      siteSetting: "adminSiteSetting",
      page: "adminPage",
      pages: "adminPages",
      brands: "adminBrands",
      hatcheries: "adminHatcheries",
      catalogues: "adminCatalogues",
      catalogue: "adminCatalogue",
    },
    user: {
      admins: "adminLists",
      farmer: "adminFarmer",
      farmers: "adminFarmers",
      staffs: "adminStaffs",
      staff: "adminStaff",
      staffAssigned: "adminStaffAssigned",
      lots: "adminlots",
    },
    dailyEntries: {
      get: "dailyEntriesGet",
      update: "dailyEntriesUpdate",
    },
    externalInputs: {
      availableStocks: "availableStocks",
    },
  },
  shop: {
    page: "PublicPage",
  },
};
export default queryKeys;
