import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { message } from "antd";

import fetcher from "../../feature";
import mutator from "../../mutator";
import queryKeys from "../../../constants/reactQuery";
import { handleSuccess } from "../../../utils/handler/success";

export function useExternalID(id: any) {
  return useQuery(
    [queryKeys.admin.lot.dayMrt, id],
    () => fetcher(`/daily-inputs/temp/${id}`),
    {
      onError: (data: any) => {
        message.error("OOPS! Link Expired");
      },
      enabled: !!id,
    }
  );
}

export function useFeedStockAvailable(id: string, enabled: boolean) {
  return useQuery(
    [queryKeys.admin.externalInputs.availableStocks],
    () => fetcher(`/feed-stock/available-for-external-inputs/${id}`),
    {
      onError: (data: any) => {
        message.error("OOPS! Feed Stocks Not Available");
      },
      enabled,
    }
  );
}

// update
export function useExternalUpdate() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: any) =>
      mutator("PUT", `/daily-inputs/temp/${data?.id}`, data?.payload),
    {
      onSuccess: (data) => {
        handleSuccess(data);
        queryClient.invalidateQueries([queryKeys.admin.dailyEntries.update]);
      },
    }
  );
}
