import * as React from "react";
import dayjs from "dayjs";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider, notification } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import router from "./router/router";
import handleError from "./utils/handler/error";
import relativeTime from "dayjs/plugin/relativeTime";
import SuspenseLoading from "./components/loading/suspenseLoading/SuspenseLoading";

dayjs.extend(relativeTime);

notification.config({
  duration: 2,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404 ||
          error?.response?.status === 440
        ) {
          return false;
        }
        return failureCount < 1;
      },
      onError: (error) => handleError(error),
    },
    mutations: {
      onError: (error) => handleError(error),
    },
  },
});

export default function App() {
  return (
    <React.Suspense fallback={<SuspenseLoading />}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#45B34A",
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <RouterProvider router={router} />
          </HelmetProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </React.Suspense>
  );
}
