import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./loading.scss";
export default function SuspenseLoading() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 32,
        color: "#45B34A",
      }}
      spin
    />
  );
  return (
    <div className='flex items-center justify-center h-full bg-primarySkLight'>
      <div className='suspenseLoading text-center'>
        <Spin indicator={antIcon} />
        <h1 className='text-primarySk text-base lg:text-xl font-medium capitalize'>
          Loading
        </h1>
      </div>
    </div>
  );
}
