export const isEven = (number: number) => number % 2 === 0;

export const getDiscountPercentage = (
  markedPrice: number,
  sellingPrice: number
) => {
  const discountPercentage = ((markedPrice - sellingPrice) / markedPrice) * 100;
  return Math.floor(discountPercentage).toFixed(0);
};

export const nepaliNumberFormat = (number: number) => {
  if (+number || +number === 0) {
    return new Intl.NumberFormat("en-IN").format(number);
  }
  return null;
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e?.fileList;
};

export const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const generateHierarchy = (
  items: any,
  titleKey: string,
  valueKey = "_id"
) => {
  if (items?.length > 0) {
    return items?.map((item: any) =>
      item?.child?.length > 0
        ? {
            title: item?.[titleKey],
            value: item?.[valueKey],
            children: generateHierarchy(item?.child, titleKey),
          }
        : {
            title: item?.[titleKey],
            value: item?.[valueKey],
          }
    );
  }

  return [];
};

export const getCombinations = (
  list: any,
  n = 0,
  result: any = [],
  current: any = []
) => {
  if (n === list.length) {
    result.push(current);
  } else {
    list[n].forEach((item: any) =>
      getCombinations(list, n + 1, result, [...current, item])
    );
  }
  return result;
};

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isColorHexValid = (value: string) => {
  if (new RegExp(/^#([A-F0-9]{3}|[A-F0-9]{6})$/i).test(value)) {
    return true;
  }
  return false;
};

export const getInfiniteQueryCurrentLength = (data: any) => {
  let count = 0;

  if (data?.pages?.length > 0) {
    data?.pages?.forEach((page: any) => {
      if (page?.data?.docs?.length > 0) {
        count += page?.data?.docs?.length;
      }
    });
  }

  return count;
};

export const getTime = (time: string) => {
  switch (time) {
    case "01":
      return `01 AM`;
    case "02":
      return `02 AM`;
    case "03":
      return `03 AM`;
    case "04":
      return `04 AM`;
    case "05":
      return `05 AM`;
    case "06":
      return `06 AM`;
    case "07":
      return `07 AM`;
    case "08":
      return `08 AM`;
    case "09":
      return `09 AM`;
    case "10":
      return `10 AM`;
    case "11":
      return `11 AM`;
    case "12":
      return `12 AM`;
    case "13":
      return `02 PM`;
    case "14":
      return `02 PM`;
    case "15":
      return `03 PM`;
    case "16":
      return `04 PM`;
    case "17":
      return `05 PM`;
    case "18":
      return `06 PM`;
    case "19":
      return `07 PM`;
    case "20":
      return `08 PM`;
    case "21":
      return `09 PM`;
    case "22":
      return `10 PM`;
    case "23":
      return `11 PM`;
    case "24":
      return `12 PM`;
    default:
      return null;
  }
};

export const handleFeedFormTitle = (level: number) => {
  switch (level) {
    case 0:
      return "Manufacturer";
    case 1:
      return "Brand Name";
    case 2:
      return "Brand Type";
    default:
      return "Manufacturer";
  }
};

export const handleHatcheryFormTitle = (level: number) => {
  switch (level) {
    case 0:
      return "Hatchery Name";
    case 1:
      return "Brand Name";
    case 2:
      return "Product Type";
    case 3:
      return "Chick Type";
    default:
      return "Hatchery Name";
  }
};

export const handleAvgBodyWeight = (
  openingStock: number,
  avgBodyWeights: any[]
) => {
  if (avgBodyWeights?.length > 0) {
    return avgBodyWeights;
  } else {
    switch (true) {
      case openingStock < 100:
        return Array.from({ length: 3 }, () => {
          return { weight: null };
        });
      case openingStock < 300:
        return Array.from({ length: 6 }, () => {
          return { weight: null };
        });
      case openingStock < 600:
        return Array.from({ length: 8 }, () => {
          return { weight: null };
        });
      case openingStock < 1000:
        return Array.from({ length: 10 }, () => {
          return { weight: null };
        });
      default:
        return Array.from({ length: 12 }, () => {
          return { weight: null };
        });
    }
  }
};

export const getLotUpdateTimeOptions = () => {
  return [
    //mornings
    {
      value: "0405",
      label: "04 AM - 05 AM",
    },
    {
      value: "0506",
      label: "05 AM - 06 AM",
    },
    {
      value: "0607",
      label: "06 AM - 07 AM",
    },
    {
      value: "0708",
      label: "07 AM - 08 AM",
    },
    {
      value: "0809",
      label: "08 AM - 09 AM",
    },
    {
      value: "0910",
      label: "09 AM - 10 AM",
    },
    {
      value: "1011",
      label: "10 AM - 11 AM",
    },

    //afternoons,nights
    {
      value: "1516",
      label: "3 PM - 4 PM",
    },
    {
      value: "1617",
      label: "4 PM - 5 PM",
    },
    {
      value: "1718",
      label: "5 PM - 6 PM",
    },
    {
      value: "1819",
      label: "6 PM - 7 PM",
    },
    {
      value: "1920",
      label: "7 PM - 8 PM",
    },
    {
      value: "2021",
      label: "8 PM - 9 PM",
    },
    {
      value: "2122",
      label: "9 PM - 10 PM",
    },
  ];
};
