 

import { useQuery } from "@tanstack/react-query";

import weatherfetcher from "../../../axiosInstances/weather";

export function useWeatherApiRequest(
  lat: string,
  lon: string,
  enabled: boolean,
  uid?: any
) {
  const appid = process.env.REACT_APP_WEATHER_API_KEY;
  const query = new URLSearchParams(
    JSON.parse(
      JSON.stringify({
        lat,
        lon,
        appid,
        units: "metric",
      })
    )
  ).toString();

  return useQuery(
    ["weather", uid],
    () => weatherfetcher(`/data/2.5/weather?${query}`),
    {
      enabled,
    }
  );
}
