import Resizer from "react-image-file-resizer";

export const resizeImage = (
  file: any,
  maxWidth: number = 1920,
  maxHeight: number = 1920
) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
};

export default resizeImage;
