import { createBrowserRouter } from "react-router-dom";

import {
  Root,
  ErrorFallback,
  Home,
  Login,
  ForgetPassword,
  ResetOtpVerify,
  ResetPassword,
  ExternalDailyInputs,
  ExternalInformation,
  ExternalSuggestion,
  Page,

  // admin
  RequireAdmin,
  AdminDashboard,
  AdminProfile,
  AdminChangePassword,
  AdminVerify,
  AdminVerfiyDetails,
  AdminVerifyDailyInputs,
  AdminUnVerify,
  AdminUnverfiyDetails,
  AdminList,
  AdminFarmerList,
  AdminFarmerDetails,
  AdminFarmerStaffDetails,
  AdminContactMessages,
  AdminOrderEnquiry,
  AdminPages,
  AdminPage,
  AdminNotice,
  AdminFarmerNotice,
  AdminScheduleNotice,
  AdminRegisterNotice,
  AdminOrderNotice,
  AdminMarket,
  AdminResources,
  AdminVetRoom,
  AdminVetRoomDetails,
  AdminCatalouge,
  AdminCatalogueForm,
  AdminBrands,
  AdminHatchery,
  AdminSiteSettings,
  // not-found
  NotFound,
} from "./imports";
import ExternalInputs from "../components/pages/externalDailyInputs/ExteralInputs";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgetPassword />,
      },
      {
        path: "/verify-otp",
        element: <ResetOtpVerify />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/external-dailyinputs/checklist/:id",
        element: <ExternalInformation />,
      },
      {
        path: "/external-dailyinputs/suggestion/:id",
        element: <ExternalSuggestion />,
      },
      {
        path: "/external-dailyinputs/form-submit/:id",
        element: <ExternalInputs />,
      },
      // {
      //   path: "/external-dailyinputs/form-submit/:id",
      //   element: <ExternalDailyInputs />,
      // },
      {
        path: "privacy-policy",
        element: <Page slug="privacy-policy" />,
      },
      {
        path: "terms-and-conditions",
        element: <Page slug="terms-and-conditions" />,
      },
      {
        path: "admin",
        element: <RequireAdmin />,
        children: [
          {
            path: "",
            element: <AdminDashboard />,
          },
          {
            path: "profile",
            element: <AdminProfile />,
          },
          {
            path: "change-password",
            element: <AdminChangePassword />,
          },
          {
            path: "notice",
            element: <AdminNotice />,
          },
          {
            path: "market",
            element: <AdminMarket />,
          },
          {
            path: "resources",
            element: <AdminResources />,
          },
          {
            path: "vet-room",
            element: <AdminVetRoom />,
          },
          {
            path: "vet-room/create",
            element: <AdminVetRoomDetails />,
          },
          {
            path: "vet-room/:id",
            element: <AdminVetRoomDetails />,
          },
          {
            path: "farmer-notice",
            element: <AdminFarmerNotice />,
          },
          {
            path: "schedule-notice",
            element: <AdminScheduleNotice />,
          },
          {
            path: "register-notice",
            element: <AdminRegisterNotice />,
          },
          {
            path: "order-notice",
            element: <AdminOrderNotice />,
          },
          {
            path: "",
            children: [
              {
                path: "farmers-lot",
                element: <AdminVerify />,
              },
              {
                path: "farmers-lot/:id",
                element: <AdminVerfiyDetails />,
              },
              {
                path: "farmers-lot/daily-inputs",
                element: <AdminVerifyDailyInputs />,
              },
              {
                path: "lot-enquiry",
                element: <AdminUnVerify />,
              },
              {
                path: "lot-enquiry/:id",
                element: <AdminUnverfiyDetails />,
              },
            ],
          },
          {
            path: "contact",
            children: [
              {
                path: "messages",
                element: <AdminContactMessages />,
              },
              {
                path: "order-enquiry",
                element: <AdminOrderEnquiry />,
              },
            ],
          },
          {
            path: "",
            children: [
              {
                path: "admin-list",
                element: <AdminList />,
              },
              {
                path: "farmer-list",
                element: <AdminFarmerList />,
              },
              {
                path: "farmer-list/:id",
                element: <AdminFarmerDetails />,
              },
              {
                path: "farmer-list/staff-assigned",
                element: <AdminFarmerStaffDetails />,
              },
            ],
          },
          {
            path: "misc",
            children: [
              {
                path: "pages",
                element: <AdminPages />,
              },
              {
                path: "pages/:slug",
                element: <AdminPage />,
              },
              {
                path: "brands",
                element: <AdminBrands />,
              },
              {
                path: "hatchery",
                element: <AdminHatchery />,
              },
              {
                path: "catalogue",
                element: <AdminCatalouge />,
              },
              {
                path: "catalogue/:id",
                element: <AdminCatalogueForm />,
              },
              {
                path: "site-setting",
                element: <AdminSiteSettings />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
