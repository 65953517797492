export const getImageNameFromURI = (uri = "") => {
  return uri.slice(uri.lastIndexOf("/") + 1);
};

export const getAbsoluteAssetPath = (uri = "") => {
  if (uri && uri?.startsWith("http")) {
    return uri;
  }

  return `${process.env.REACT_APP_STATIC_URL}${uri}`;
};
