import * as React from "react";
import { Modal, Upload } from "antd";
import { AiOutlinePlus } from "react-icons/ai";

import { getBase64 } from "../../../../utils";
import resizeImage from "../../../../utils/imageResizer";
import axiosInstance from "../../../../axiosInstances";
import handleError from "../../../../utils/handler/error";

export default function ImageUpload(props: any) {
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const customRequest = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const formData = new FormData();
    const resizedFile: any = await resizeImage(file);

    formData.append("upload", resizedFile);

    try {
      const { data } = await axiosInstance({
        method: "POST",
        url: `/upload?folder=${props?.folder || ""}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) =>
          onProgress({
            percent: (event?.loaded / event?.total) * 100,
          }),
      });
      onSuccess(data);
    } catch (error) {
      handleError(error);
      onError(error);
    }
  };

  const upload = (
    <Upload
      accept="image/*"
      onPreview={handlePreview}
      customRequest={customRequest}
      {...props}
    >
      {!props?.hideUploader && (
        <div>
          <AiOutlinePlus />

          <div> {!props?.langChecked ? "अपलोड" : "Upload"}</div>
        </div>
      )}
    </Upload>
  );

  return (
    <div>
      {upload}
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt={previewTitle} src={previewImage} className="w-full" />
      </Modal>
    </div>
  );
}
